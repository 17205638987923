@import "../config";
@import "../utils";

.about-pg {
  margin-bottom: 7rem;
  canvas {
    // width: 100%;
    height: 50vh;
  }
  .about-stats {
    width: 90%;
    max-width: 60rem;
    margin: 6.5rem auto 0;
    display: flex;
    justify-content: space-around;
    .stat-contributions {
      position: relative;
      top: -4rem;
    }
    .stat {
      text-align: center;
      p {
        line-height: 1.5;
        font-weight: 300;
      }
    }
  }
  .about-summary {
    width: 90%;
    max-width: 50rem;
    margin: 0rem auto 3rem;
    padding: 0 2rem;
    text-align: center;
    position: relative;
    .prof-img {
      margin: auto;
      border: 2px solid map-get($colors, app-dark);
      background-color: #9e8fb2;
      background-image: linear-gradient(315deg, #9e8fb2 0%, #a7acd9 74%);
      border-radius: 50%;
      width: 300px;
      margin-bottom: 2rem;
    }
    h3 {
      text-indent: 5rem;
      text-align: justify;
    }
    p {
      text-indent: 0.5rem;
      text-align: justify;
      word-wrap: break-word;
      color: map-get($colors, app-dark);
      @include mq("phablet") {
        text-indent: 3rem;
      }
      font-weight: 300;
      line-height: 1.75;
      @include font-size($p-font-sizes);
      span {
        font-weight: normal;
      }
      a {
        color: black;
        span {
          font-weight: normal;
          color: black;
        }
      }
    }
  }
}
