@font-face {
  font-family: SpartanMB;
  src: url(./font/SpartanMB-Extra-Bold.otf);
  font-weight: bold;
}

@font-face {
  font-family: SpartanMB;
  src: url(./font/SpartanMB-Regular.otf);
  font-weight: normal;
}
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap");

$font: "SpartanMB";
$font-2: "Lato", sans-serif;
$font-hand: "Indie Flower", cursive;
$colors: (
  app-white: rgb(250, 250, 250),
  app-less-white: rgb(243, 242, 242),
  app-dark: #3d3a3a,
  app-close: rgb(255, 95, 92),
  app-min: rgb(255, 189, 68),
  app-open: rgb(0, 202, 76),
  app-orange: rgb(235, 102, 55),
  app-solo: #77a699,
  app-dark-solo: #5c8579,
  app-client: #03046e,
  app-dark-client: #03044d,
  app-team: #88022f,
  app-dark-team: #660022,
  app-article: #5e4ae3,
  app-dark-article: #4e3dbe,
  app-snippet: #7a3b69,
  app-dark-snippet: #642d56,
  app-demo: #e2643a,
  app-dark-demo: #ce6340,
  app-3d: #06bbaf,
  app-dark-3d: #039289,
  app-template: #656256,
  app-dark-template: #535146,
  dark: #000000,
  modal-white: rgb(247, 247, 247),
  null: rgba(0, 0, 0, 0),
  success: rgb(0, 235, 94),
  err: rgb(255, 90, 145),
  focus: rgb(0, 149, 235),
);
$animations: (
  expand-down: expand-down 0.45s ease-in both,
  fade-in: fadeIn 1s ease-out,
  fade-in-2: fadeIn 0.4s ease-out,
  fade-in-3: fadeIn2 0.4s ease-in,
  from-top: fromTop 0.8s cubic-bezier(0.39, 0.575, 0.565, 1),
  from-right: fromRight 0.5s cubic-bezier(0.39, 0.575, 0.565, 1),
  from-left: fromLeft 0.5s cubic-bezier(0.39, 0.575, 0.565, 1),
  scale-left: scaleLeft 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both,
  fold-up: fold-up 0.4s linear both,
  ping: ping 0.8s ease-in-out infinite both,
  transition-1: all 0.4s cubic-bezier(0.75, 0, 0.125, 1),
  transition-2: all 0.4s,
  transition-3: all 0.2s,
  zoom: zoomIn 0.3s ease-out,
);

$breakpoints: (
  "phone-small": 350px,
  "phone": 400px,
  "phone-wide": 480px,
  "phablet": 560px,
  "tablet-small": 640px,
  "tablet": 768px,
  "tablet-med": 900px,
  "tablet-wide": 1024px,
  "desktop-small": 1150px,
  "desktop": 1248px,
  "desktop-wide": 1440px,
  "ultra-wide": 2000px,
);

$bigh-font-sizes: (
  null: calc(3.1rem + 0.25vw),
  map-get($breakpoints, "phone-wide"): calc(3.3rem + 0.25vw),
  map-get($breakpoints, "phablet"): calc(3.5rem + 0.25vw),
  map-get($breakpoints, "tablet"): calc(4rem + 0.25vw),
  map-get($breakpoints, "desktop"): calc(4.5rem + 0.25vw),
  map-get($breakpoints, "ultra-wide"): calc(5rem + 0.25vw),
);

$h1-font-sizes: (
  null: calc(1.9rem + 0.25vw),
  map-get($breakpoints, "phone-wide"): calc(2rem + 0.25vw),
  map-get($breakpoints, "phablet"): calc(2.4rem + 0.25vw),
  map-get($breakpoints, "tablet"): calc(2.8rem + 0.25vw),
  map-get($breakpoints, "desktop"): calc(3.2rem + 0.25vw),
  map-get($breakpoints, "ultra-wide"): calc(4rem + 0.25vw),
);

$h1-font-sizes: (
  null: calc(1.9rem + 0.25vw),
  map-get($breakpoints, "phone-wide"): calc(2rem + 0.25vw),
  map-get($breakpoints, "phablet"): calc(2.4rem + 0.25vw),
  map-get($breakpoints, "tablet"): calc(2.8rem + 0.25vw),
  map-get($breakpoints, "desktop"): calc(3rem + 0.25vw),
  map-get($breakpoints, "ultra-wide"): calc(3rem + 0.25vw),
);

$h2-font-sizes: (
  null: calc(1.4rem + 0.2vw),
  map-get($breakpoints, "phone-wide"): calc(1.5rem + 0.2vw),
  map-get($breakpoints, "phablet"): calc(1.6rem + 0.2vw),
  map-get($breakpoints, "tablet"): calc(1.7rem + 0.2vw),
  map-get($breakpoints, "desktop"): calc(1.9rem + 0.2vw),
  map-get($breakpoints, "ultra-wide"): calc(2.5rem + 0.2vw),
);

$h3-font-sizes: (
  null: calc(1.3rem + 0.15vw),
  map-get($breakpoints, "phablet"): calc(1.5rem + 0.15vw),
  map-get($breakpoints, "tablet"): calc(1.6rem + 0.15vw),
  map-get($breakpoints, "desktop"): calc(1.9rem + 0.15vw),
  map-get($breakpoints, "ultra-wide"): calc(1.9rem + 0.15vw),
);

$p-font-sizes: (
  null: calc(1rem + 0.15vw),
  map-get($breakpoints, "phablet"): calc(1.2rem + 0.15vw),
  map-get($breakpoints, "tablet"): calc(1.3rem + 0.15vw),
  map-get($breakpoints, "desktop"): calc(1.4rem + 0.15vw),
  map-get($breakpoints, "ultra-wide"): calc(1.6rem + 0.15vw),
);

$sub-font-sizes: (
  null: calc(1rem + 0.1vw),
  map-get($breakpoints, "phablet"): calc(1rem + 0.1vw),
  map-get($breakpoints, "tablet"): calc(1rem + 0.1vw),
  map-get($breakpoints, "desktop"): calc(1.3rem + 0.1vw),
  map-get($breakpoints, "ultra-wide"): calc(1.3rem + 0.1vw),
);

// Functions
@function responsive-theme($color) {
  @if (lightness($color) > 70) {
    @return map-get($colors, dark);
  } @else {
    @return map-get($colors, app-white);
  }
}

// Mixins
@mixin font-size($fs-map) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      font-size: $fs-font-size;
    } @else {
      @media screen and (min-width: $fs-breakpoint) {
        font-size: $fs-font-size;
      }
    }
  }
}

@mixin position($position, $args) {
  @each $o in top right bottom left {
    $i: index($args, $o);
    @if $i and $i + 1 <= length($args) and type-of(nth($args, $i + 1)) == number
    {
      #{$o}: nth($args, $i + 1);
    }
  }
  position: $position;
}
// Positioning helpers
@mixin absolute($args: "") {
  @include position(absolute, $args);
}
@mixin fixed($args: "") {
  @include position(fixed, $args);
}
@mixin relative($args: "") {
  @include position(relative, $args);
}

@mixin push--auto {
  margin: {
    left: auto;
    right: auto;
  }
}

@mixin pseudo($display: block, $pos: absolute, $content: "") {
  content: $content;
  display: $display;
  position: $pos;
}

@mixin responsive-ratio($x, $y, $pseudo: false) {
  $padding: unquote(($y / $x) * 100 + "%");
  @if $pseudo {
    &:before {
      @include pseudo($pos: relative);
      width: 100%;
      padding-top: $padding;
    }
  } @else {
    padding-top: $padding;
  }
}

@mixin css-triangle(
  $color,
  $direction,
  $size: 6px,
  $position: absolute,
  $round: false
) {
  @include pseudo($pos: $position);
  width: 0;
  height: 0;
  @if $round {
    border-radius: 3px;
  }
  @if $direction == down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
    margin-top: 0 - round($size / 2.5);
  } @else if $direction == up {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
    margin-bottom: 0 - round($size / 2.5);
  } @else if $direction == right {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    margin-right: -$size;
  } @else if $direction == left {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    margin-left: -$size;
  }
}

@mixin set-background($color) {
  background-color: $color;
  color: responsive-theme($color);
}

@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}
