@import "../config";
@import "../utils";

.works-pg {
  .search-form {
    @extend %searchForm;
  }
  .works-container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    .works-count {
      text-align: center;
      margin: -3.25rem auto 4rem;
      color: map-get($colors, app-white);
      font-style: italic;
      opacity: 0.75;
      font-weight: 350;
    }
    @extend %itemPreview;
  }
}
