@import "config";

html,
body {
  width: 100%;
  height: 100%;
  .Modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 3;
    .Modal__inner {
      z-index: 1;
      margin: auto;
      overflow-y: auto;
      background-color: map-get($colors, app-white);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      -o-animation: map-get($animations, zoom);
      -moz-animation: map-get($animations, zoom);
      -webkit-animation: map-get($animations, zoom);
      animation: map-get($animations, zoom);
      width: 100%;
      height: calc(100% - 10rem);
      border: 1px solid map-get($colors, app-dark);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
      border: 0.44rem solid map-get($colors, app-dark);
      border-top: 2rem solid map-get($colors, app-dark);
      -moz-border-radius-topleft: 0.4rem;
      -moz-border-radius-topright: 0.4rem;
      border-top-left-radius: 0.4rem;
      border-top-right-radius: 0.4rem;
      .modal-pg {
        position: relative;
        height: 100%;
        width: 100%;
        background-color: map-get($colors, app-white);
        padding: 3rem 2rem;
        -o-animation: map-get($animations, fade-in);
        -moz-animation: map-get($animations, fade-in);
        -webkit-animation: map-get($animations, fade-in);
        animation: map-get($animations, fade-in);
        &.byte-modal {
          header {
            h1 {
              @include font-size($h2-font-sizes);
            }
            h2 {
              @include font-size($h3-font-sizes);
            }
          }
          .modal-links {
            margin: 0 auto 2rem !important;
          }
        }
        article {
          width: 100%;
          max-width: 60rem;
          margin: auto;
          > h2 {
            display: none;
          }
        }
        .modal-content {
          height: auto;
          width: 100%;
          overflow-y: scroll;
          text-align: center;
          padding-bottom: 3rem;
          .modal-preview {
            text-align: center;
            width: 100%;
            max-width: 60rem;
            margin: 1rem auto;
          }
          .modal-links {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            max-width: 55rem;
            margin: 0 auto 4rem;
            a {
              background: map-get($colors, app-dark);
              color: map-get($colors, app-white);
              text-decoration: none;
              display: block;
              padding: 0.5rem 1rem;
              margin: auto 1rem;
              line-height: 1.25;
              border-radius: 0.4rem;
              @include font-size($p-font-sizes);
              opacity: 0.9;
              -moz-transition: map-get($animations, transition-1);
              -o-transition: map-get($animations, transition-1);
              -webkit-transition: map-get($animations, transition-1);
              transition: map-get($animations, transition-1);
              &:hover,
              &:focus,
              &:active {
                -moz-transition: map-get($animations, transition-1);
                -o-transition: map-get($animations, transition-1);
                -webkit-transition: map-get($animations, transition-1);
                transition: map-get($animations, transition-1);
                background: black;
                opacity: 1;
              }
              svg {
                margin-right: 0.75rem;
                width: 25px;
                height: 25px;
                stroke-width: 1.75;
                position: relative;
                top: 0.1rem;
              }
            }
          }
          .modal-desc {
            width: 90%;
            max-width: 50rem;
            margin: 4rem auto;
            word-wrap: break-word;
            line-height: 1.5;
            text-align: justify;
            h3 {
              text-align: center;
            }
            p {
              text-indent: 4rem;
              span.note {
                display: block;
                font-style: italic;
                margin-top: 2rem;
                text-indent: 0;
              }
            }
          }
          .modal-pagination {
            button {
              margin: 0.25rem 0;
              padding: 0.5rem 0.75rem 0.4rem;
              background: map-get($colors, app-white);
              svg {
                width: 2rem;
                height: 2rem;
              }
              &:hover {
                background: rgb(241, 240, 240);
              }
              &:active {
                background: rgb(230, 228, 228);
              }
              border: 1px solid
                rgba($color: map-get($colors, app-dark), $alpha: 0.4);
              cursor: pointer;
              &:disabled {
                background: map-get($colors, app-white);
                cursor: default;
                svg {
                  opacity: 0.4;
                }
              }
              &:first-child {
                border-top-left-radius: 0.4rem;
                border-bottom-left-radius: 0.4rem;
                border-right: none;
              }
              &:last-child {
                border-top-right-radius: 0.4rem;
                border-bottom-right-radius: 0.4rem;
                border-left: none;
              }
              &:nth-child(2) {
                border-right: none;
              }
            }
          }
          .guest-creds {
            width: 100%;
            max-width: 60rem;
            margin: 0 auto;
            .guest-head {
              background: map-get($colors, app-dark);
              color: map-get($colors, app-white);
              display: flex;
              justify-content: space-between;
              padding: 1.25rem 1.5rem;
              cursor: pointer;
              p {
                svg {
                  margin-right: 1rem;
                  position: relative;
                  top: 0.2rem;
                }
                margin: 0;
                line-height: 0;
              }
            }
            ul {
              text-align: left;
              width: 100%;
              padding-left: 1.75rem;
              padding: 2rem 1.75rem;
              border: 2px solid map-get($colors, app-dark);
              background: darken(
                $color: map-get($colors, app-white),
                $amount: 3.5
              );
              li {
                width: 100%;
                line-height: 2;
                @include font-size($p-font-sizes);
                b {
                  font-weight: 400;
                }
                span {
                  position: relative;
                  font-weight: 300;
                  svg {
                    cursor: pointer;
                    position: absolute;
                    top: 1rem;
                    right: 0.5rem;
                  }
                  display: block;
                  width: 100%;
                  padding: 0.5rem;
                  font-style: italic;
                  border: 1px solid map-get($colors, app-dark);
                  background: map-get($colors, app-white);
                  &.email-active,
                  &.pwd-active {
                    -o-animation: map-get($animations, fade-in-2);
                    -moz-animation: map-get($animations, fade-in-2);
                    -webkit-animation: map-get($animations, fade-in-2);
                    animation: map-get($animations, fade-in-2);
                  }
                }
              }
            }
          }
        }
        .carousel-root {
          max-width: 60rem;
          margin: auto;
          background: map-get($colors, app-less-white);
          .legend {
            background: map-get($colors, app-dark);
            @include font-size($sub-font-sizes);
          }
          .carousel .thumb {
            border: 3px solid map-get($colors, app-white);
            margin-bottom: 0.5rem;
          }
          .carousel .thumb.selected,
          .carousel .thumb:hover {
            border: 3px solid map-get($colors, app-dark);
          }
          .carousel .control-dots .dot {
            width: 15px;
            height: 15px;
            background: map-get($colors, app-white);
            box-shadow: 1px 1px 3px 0px rgba(32, 32, 32, 0.89);
          }
        }
        .modal-controls {
          display: block;
          z-index: 3;
        }
        .modal-btn {
          position: fixed;
          top: 0.4rem;
          height: 1.2rem;
          width: 1.2rem;
          border-radius: 50%;
          border: 0;
          cursor: pointer;
          -o-animation: map-get($animations, fade-in-3);
          -moz-animation: map-get($animations, fade-in-3);
          -webkit-animation: map-get($animations, fade-in-3);
          animation: map-get($animations, fade-in-3);
        }
        .close-modal {
          background: map-get($colors, app-close);
          left: 0.41rem;
        }
        .min-modal {
          background: map-get($colors, app-min);
          left: 2.12rem;
        }
        .resize-modal {
          background: map-get($colors, app-open);
          left: 3.73rem;
        }
      }
    }
  }
}
