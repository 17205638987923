@import "config";
/* ________________________________________ */

/****** Solomon Zelenko's RESET *******/
/* Based on normalize.css & meyer's css reset */
/*           https://github.com/zempo         */

/* ________________________________________ */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-size: 10px; /*for using REM units*/
}

body {
  background: map-get($colors, app-white);
  overflow: overlay !important;
  margin: 0;
}

b,
strong {
  font-weight: bolder;
}

html,
body,
div,
span,
applet,
object,
iframe {
  font: inherit;
  vertical-align: baseline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font: inherit;
  vertical-align: baseline;
}

p,
blockquote,
pre {
  font: inherit;
  vertical-align: baseline;
}

a,
abbr,
acronym,
address,
big,
cite,
code,
del {
  font: inherit;
  vertical-align: baseline;
}

dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike {
  font: inherit;
  vertical-align: baseline;
}

strong,
sub,
sup,
tt,
var,
b,
u,
i {
  font: inherit;
  vertical-align: baseline;
}

center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend {
  font: inherit;
  vertical-align: baseline;
}

table,
tbody,
tfoot,
thead,
tr,
th,
td {
  font: inherit;
  vertical-align: baseline;
}

caption,
article,
aside canvas,
details {
  font: inherit;
  vertical-align: baseline;
}

embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav {
  font: inherit;
  vertical-align: baseline;
}

output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
  padding-left: 0;
  margin: 0 auto;
}

/* Text-level semantics
   ========================================================================== */

/**
 * Remove the gray background on active links in IE 10.
 */

a {
  background-color: transparent;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input {
  /* 1 */
  overflow: visible;
}

/**
  * Remove the inheritance of text transform in Edge, Firefox, and IE.
  * 1. Remove the inheritance of text transform in Firefox.
  */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
  * Correct the inability to style clickable types in iOS and Safari.
  */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
  * Remove the inner border and padding in Firefox.
  */

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted rgb(228, 154, 18);
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from fieldset elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    "fieldset" elements in all browsers.
 */

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

progress {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */

textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to "inherit" in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/* Interactive
   ========================================================================== */

/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */

details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Misc
   ========================================================================== */

/**
 * Add the correct display in IE 10+.
 */

template {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */

[hidden] {
  display: none;
}

/**
 * Prevent "sub" and "sup" elements from affecting the line height in
 * all browsers.
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}
